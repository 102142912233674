<template>
  <!-- 左边小票模板区域 -->
  <div class="left-template x-x">
    <div class="menus">
      <div
        class="menu pointer"
        :class="{ active: curPage === '模板' }"
        @click="curPage = '模板'"
      >
        <div class="icon">
          <img src="@/assets/images/template-icon.png" alt="加载失败" />
        </div>
        <div class="name">模板</div>
      </div>
      <div
        class="menu pointer"
        :class="{ active: curPage === '参数' }"
        @click="curPage = '参数'"
      >
        <div class="icon">
          <img src="@/assets/images/parameter-icon.png" alt="加载失败" />
        </div>
        <div class="name">参数</div>
      </div>
    </div>
    <div class="content">
      <!-- 模板 -->
      <div class="template" v-if="curPage === '模板'">
        <div class="btn pointer" @click="addTemplate">新增模板</div>
        <div class="template-list">
          <el-scrollbar>
            <div
              class="template-item"
              :class="{ active: item.isCurrentUseFormat }"
              v-for="item in receiptFormatList"
              :key="item.formatId"
            >
              <div class="serialNum">{{ item.serialNum }}</div>
              <div class="template">
                <textarea
                  v-if="item.formatType !== 1"
                  autocomplete="off"
                  rows="2"
                  placeholder=""
                  class="el-textarea__inner"
                  style="resize: none"
                  :style="`font-size:${item.headCaptionFontSize}px;font-weight:${
                    item.isHeadCaptionBold ? '700' : '400'
                  }`"
                  v-model="item.formatHeadCaption"
                >
                </textarea>
                <div class="formatContent">
                  <pre v-html="item.repFormatContent" style="white-space: pre-wrap"></pre>
                </div>
                <textarea
                  v-if="item.formatType !== 1"
                  autocomplete="off"
                  rows="2"
                  placeholder="请输入底部标题"
                  class="el-textarea__inner"
                  style="resize: none"
                  v-model="item.formatBottomCaption"
                  :style="`font-size:${item.bottomCaptionFontSize}px;font-weight:${
                    item.isBottomCaptionBold ? '700' : '400'
                  }`"
                ></textarea>
              </div>
              <div class="templateBtns">
                <span
                  class="use-btn x-fc"
                  v-show="!item.isCurrentUseFormat"
                  @click="handleTemplate('use', item.formatId)"
                  >使用此模板</span
                >
                <span class="use-btn x-fc" v-show="item.isCurrentUseFormat"
                  >模板使用中</span
                >
                <div class="line"></div>
                <span
                  class="edit-btn x-fc"
                  v-if="!item.isSysDefaultFormat"
                  @click="handleTemplate('edit', item.formatId)"
                  >编辑</span
                >
                <span v-else class="edit-btn disabled x-fc">编辑</span>
                <div class="line"></div>
                <span class="copy-btn x-fc" @click="handleTemplate('copy', item.formatId)"
                  >复制</span
                >
                <div class="line"></div>
                <span
                  class="del-btn x-fc"
                  v-if="!item.isSysDefaultFormat"
                  @click="handleTemplate('del', item.formatId)"
                  >删除</span
                >
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <!-- 参数 -->
      <div class="parameter" v-else>
        <el-input
          v-model="searchParameter"
          prefix-icon="el-icon-search"
          placeholder="搜索参数字段"
        ></el-input>
        <div class="parameter-list">
          <el-scrollbar>
            <div
              class="parameter-item x-bc pointer"
              v-for="item in searchParameterList"
              :key="item.text"
              @click="addParameter(item)"
            >
              <div class="name">{{ item.name }}</div>
              <div class="icon el-icon-circle-plus-outline"></div>
            </div>
          </el-scrollbar>
        </div>
        <div class="moreParameterBtn pointer">查看详细参数</div>
      </div>
    </div>
  </div>
</template>

<script>
import { itmes } from "@/utils/index.js"; //实时时间
import { cloneDeep } from "lodash";
import parameter from "./index.js";

export default {
  name: "LeftTemplate",
  dicts: ["sys_custom_receipt"],
  props: {
    //小票模板列表
    receiptFormatList: {
      type: Array,
    },
    //设置模板类型
    setTemplateType: {
      type: String,
      default: "cashier",
    },
    //小票模板参数
    receiptFormat: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      visible: true,
      //当前菜单(模板/参数)
      curPage: "模板",
      //搜索的参数
      searchParameter: undefined,
    };
  },
  watch: {
    // 侦听传过来的小票模板列表 模板替换真实数据展示
    receiptFormatList: {
      handler(val) {
        let serialNum = val.length;
        val.map((item) => {
          if (item.formatContent) {
            //小票内容区域分行,添加html标签
            let repArr = item.formatContent.split("\n");
            repArr = repArr
              .map((v) => {
                v = `<div>${v}</div>`;
                return v;
              })
              .join("");
            let dateTime = itmes(new Date());
            dateTime = dateTime.timeTextFormatOne;
            //替换真实数据展示
            let repFormatContent;
            //区分不同模板替换数据
            if (this.setTemplateType == "cashier") {
              //收银小票
              repFormatContent = repArr
                .replace(new RegExp("#{门店名称}", "g"), "隼云科技")
                .replace(new RegExp("#{门店地址}", "g"), "金宝大厦8408")
                .replace(new RegExp("#{联系电话}", "g"), "86612345")
                .replace(new RegExp("#{收银员姓名}", "g"), "陈陈陈")
                .replace(new RegExp("#{收银员编号}", "g"), "085")
                .replace(new RegExp("#{导购员}", "g"), "林林林")
                .replace(new RegExp("#{导购员编号}", "g"), "095")
                .replace(new RegExp("#{小票号}", "g"), "No123456")
                .replace(new RegExp("#{支付方式}", "g"), "<div>隼云付:88</div>")
                .replace(new RegExp("#{总计}", "g"), "100")
                .replace(new RegExp("#{件数}", "g"), "5")
                .replace(new RegExp("#{应收}", "g"), "88")
                .replace(new RegExp("#{原价}", "g"), "200")
                .replace(new RegExp("#{整单折扣}", "g"), "0.8折")
                .replace(new RegExp("#{收款}", "g"), "100")
                .replace(new RegExp("#{实收}", "g"), "100")
                .replace(new RegExp("#{找零}", "g"), "30")
                .replace(new RegExp("#{优惠}", "g"), "18")
                .replace(new RegExp("#{商品编码}", "g"), "866")
                .replace(new RegExp("#{条码}", "g"), "066")
                .replace(new RegExp("#{商品名称}", "g"), "西瓜")
                .replace(new RegExp("#{单位}", "g"), "个")
                .replace(new RegExp("#{规格}", "g"), "500g")
                .replace(new RegExp("#{加值属性}", "g"), "加糖")
                .replace(new RegExp("#{数量}", "g"), "5")
                .replace(new RegExp("#{单价}", "g"), "18")
                .replace(new RegExp("#{售价}", "g"), "16")
                .replace(new RegExp("#{金额}", "g"), "88")
                .replace(new RegExp("#{日期时间}", "g"), dateTime)
                .replace(new RegExp("#{下单时间}", "g"), dateTime)
                .replace(new RegExp("#{会员姓名}", "g"), "陈陈陈")
                .replace(new RegExp("#{会员号}", "g"), "123456")
                .replace(new RegExp("#{会员等级}", "g"), "普通会员")
                .replace(new RegExp("#{手机号}", "g"), "15815056789")
                .replace(new RegExp("#{会员卡本次消费金额}", "g"), "100")
                .replace(new RegExp("#{消费后余额}", "g"), "100")
                .replace(new RegExp("#{消费前余额}", "g"), "200")
                .replace(new RegExp("#{积分}", "g"), "100")
                .replace(new RegExp("#{本次积分}", "g"), "100")
                .replace(new RegExp("#{消耗积分}", "g"), "0")
                .replace(new RegExp("#{打印时间}", "g"), "2024-05-03 19:48:00")
                .replace(new RegExp("#{取餐码}", "g"), "00001")
                .replace(new RegExp("#item", "g"), "")
                .replace(
                  "#IMG{图片1}",
                  `<img style="width:120px;height:120px" src="${item.formatLogoUrl}"/>`
                )
                .replace(
                  "#IMG{图片2}",
                  `<img style="width:200px;height:200px" src="${item.formatQrCodeUrl}"/>`
                );
            }
            if (
              this.setTemplateType == "kitchenPrint" ||
              this.setTemplateType == "orderMake"
            ) {
              //厨打小票
              repFormatContent = repArr
                .replace(new RegExp("#{打印机名称}", "g"), "厨房打印机")
                .replace(new RegExp("#{手工单据号}", "g"), "NO001")
                .replace(new RegExp("#{磅数}", "g"), "1磅")
                .replace(new RegExp("#{尺寸}", "g"), "8寸")
                .replace(new RegExp("#{样式}", "g"), "样式1")
                .replace(new RegExp("#{商品编码}", "g"), "866")
                .replace(new RegExp("#{条码}", "g"), "066")
                .replace(new RegExp("#{商品名称}", "g"), "西瓜")
                .replace(new RegExp("#{单位}", "g"), "个")
                .replace(new RegExp("#{规格}", "g"), "500g")
                .replace(new RegExp("#{加值属性}", "g"), "加糖")
                .replace(new RegExp("#{数量}", "g"), "5")
                .replace(new RegExp("#{单价}", "g"), "18")
                .replace(new RegExp("#{售价}", "g"), "16")
                .replace(new RegExp("#{金额}", "g"), "88")
                .replace(new RegExp("#{备注}", "g"), "多加叉子")
                .replace(new RegExp("#{预付款}", "g"), "28")
                .replace(new RegExp("#{余款}", "g"), "100")
                .replace(new RegExp("#{运费}", "g"), "8")
                .replace(new RegExp("#{下单时间}", "g"), dateTime)
                .replace(new RegExp("#{订单来源}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{配送地址}", "g"), "配送地址")
                .replace(new RegExp("#{收货人}", "g"), "陈陈陈")
                .replace(new RegExp("#{电话}", "g"), "13888888888")
                .replace(new RegExp("#{打印时间}", "g"), dateTime)
                .replace(new RegExp("#{流水号}", "g"), "00001")
                .replace(new RegExp("#{预约时间}", "g"), dateTime)
                .replace(new RegExp("#{取货时间}", "g"), "2024-07-02 08:30-09:00")
                .replace(new RegExp("#{配送时间}", "g"), "2024-07-02 08:30-09:00")
                .replace(new RegExp("#{订单号}", "g"), "NO0022023080300001")
                .replace(new RegExp("#{取货门店}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{配送门店}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{配送方式}", "g"), "同城配送")
                .replace(new RegExp("#{门店}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{预订门店}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{桌台号}", "g"), "001")
                .replace(new RegExp("#{桌台名称}", "g"), "1桌1号")
                .replace(new RegExp("#{就餐方式}", "g"), "堂食")
                .replace(new RegExp("#{打印时间}", "g"), "2024-05-03 19:48:00")
                .replace(new RegExp("#{取餐码}", "g"), "00001")
                .replace(new RegExp("#{核销码}", "g"), "核销码")
                .replace(new RegExp("#item", "g"), "");
            }
            if (this.setTemplateType == "orderMake") {
              //订单打印
              repFormatContent = repArr
                .replace(new RegExp("#{打印机名称}", "g"), "厨房打印机")
                .replace(new RegExp("#{订单号}", "g"), "NO0022023080300001")
                .replace(new RegExp("#{手工单据号}", "g"), "NO001")
                .replace(new RegExp("#{日期时间}", "g"), dateTime)
                .replace(new RegExp("#{下单时间}", "g"), dateTime)
                .replace(new RegExp("#{磅数}", "g"), "1磅")
                .replace(new RegExp("#{尺寸}", "g"), "8寸")
                .replace(new RegExp("#{样式}", "g"), "样式1")
                .replace(new RegExp("#{餐台号}", "g"), "A01")
                .replace(new RegExp("#{流水号}", "g"), "00001")
                .replace(new RegExp("#{订单来源}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{打印时间}", "g"), dateTime)
                .replace(new RegExp("#{商品编码}", "g"), "866")
                .replace(new RegExp("#{条码}", "g"), "066")
                .replace(new RegExp("#{商品名称}", "g"), "西瓜")
                .replace(new RegExp("#{单位}", "g"), "个")
                .replace(new RegExp("#{规格}", "g"), "500g")
                .replace(new RegExp("#{加值属性}", "g"), "加糖")
                .replace(new RegExp("#{数量}", "g"), "5")
                .replace(new RegExp("#{单价}", "g"), "18")
                .replace(new RegExp("#{售价}", "g"), "16")
                .replace(new RegExp("#{金额}", "g"), "88")
                .replace(new RegExp("#{支付方式}", "g"), "<div>隼云付:88</div>")
                .replace(new RegExp("#{收货人}", "g"), "陈**")
                .replace(new RegExp("#{门店电话}", "g"), "86686688")
                .replace(new RegExp("#{预定门店}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{预约时间}", "g"), dateTime)
                .replace(new RegExp("#{取货门店}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{取货时间}", "g"), dateTime)
                .replace(new RegExp("#{配送门店}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{配送方式}", "g"), "同城配送")
                .replace(new RegExp("#{取货方式}", "g"), "门店自提")
                .replace(new RegExp("#{配送时间}", "g"), dateTime)
                .replace(new RegExp("#{联系电话}", "g"), "136123456789")
                .replace(new RegExp("#{送货地址}", "g"), "宝安区灵芝地铁口")
                .replace(new RegExp("#{预付款}", "g"), "28")
                .replace(new RegExp("#{余款}", "g"), "100")
                .replace(new RegExp("#{运费}", "g"), "8")
                .replace(new RegExp("#{备注}", "g"), "多放草莓")
                .replace(new RegExp("#{打印时间}", "g"), "2024-05-03 19:48:00")
                .replace(new RegExp("#{取餐码}", "g"), "00001")
                .replace(new RegExp("#{核销码}", "g"), "核销码")
                .replace(new RegExp("#item", "g"), "");
            }
            if (this.setTemplateType == "delivery") {
              //配送小票
              //替换真实数据展示
              repFormatContent = repArr
                .replace(new RegExp("#{订单号}", "g"), "NO0022023080300001")
                .replace(new RegExp("#{订单来源}", "g"), "美团")
                .replace(new RegExp("#{配送时间}", "g"), dateTime)
                .replace(new RegExp("#{流水号}", "g"), "888")
                .replace(new RegExp("#{取货时间}", "g"), dateTime)
                .replace(new RegExp("#{取货门店}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{配送方式}", "g"), "商家配送")
                .replace(new RegExp("#{配送门店}", "g"), "喜洋洋门店")
                .replace(new RegExp("#{下单时间}", "g"), dateTime)
                .replace(new RegExp("#{打印时间}", "g"), dateTime)
                .replace(new RegExp("#{商品编码}", "g"), "866")
                .replace(new RegExp("#{条码}", "g"), "066")
                .replace(new RegExp("#{商品名称}", "g"), "西瓜")
                .replace(new RegExp("#{单位}", "g"), "个")
                .replace(new RegExp("#{规格}", "g"), "500g")
                .replace(new RegExp("#{加值属性}", "g"), "加糖")
                .replace(new RegExp("#{数量}", "g"), "5")
                .replace(new RegExp("#{单价}", "g"), "18")
                .replace(new RegExp("#{售价}", "g"), "16")
                .replace(new RegExp("#{金额}", "g"), "88")
                .replace(new RegExp("#{备注}", "g"), "多放草莓")
                .replace(new RegExp("#{配送费}", "g"), "12")
                .replace(new RegExp("#{打包费}", "g"), "1")
                .replace(new RegExp("#{合计金额}", "g"), "128")
                .replace(new RegExp("#{支付方式}", "g"), "<div>隼云付:128</div>")
                .replace(new RegExp("#{收货人}", "g"), "陈**")
                .replace(new RegExp("#{电话}", "g"), "15812366077")
                .replace(new RegExp("#{收货地址}", "g"), "宝安区灵芝地铁口")
                .replace(new RegExp("#{打印时间}", "g"), "2024-05-03 19:48:00")
                .replace(new RegExp("#{取餐码}", "g"), "00001")
                .replace(new RegExp("#item", "g"), "");
            }
            this.$set(item, "repFormatContent", repFormatContent);
            this.$set(item, "serialNum", serialNum + "#"); //定义序号标识
            serialNum = serialNum - 1;
          }
        });
      },
      immediate: true,
      deep: true,
    },
  },
  computed: {
    // 搜索参数字段过滤显示数据
    searchParameterList() {
      //设置不同模板 不同参数
      let fieldName = "";
      if (this.setTemplateType == "cashier") fieldName = "cashierParameterList";
      if (this.setTemplateType == "kitchenPrint") fieldName = "kitchenPrintParameterList";
      if (this.setTemplateType == "orderMake") fieldName = "orderMakeParameterList";
      if (this.setTemplateType == "delivery") fieldName = "deliveryParameterList";
      fieldName =
        this.receiptFormat.formatType === 1 ? fieldName + "HighLevel" : fieldName;
      if (!this.searchParameter) {
        return parameter[fieldName];
      }
      const arr = parameter[fieldName].filter((v) => {
        return v.name.indexOf(this.searchParameter) !== -1; // 字符串是否包含该字符
      });
      return arr;
    },
  },
  methods: {
    //添加参数
    addParameter(parameter) {
      let item = cloneDeep(parameter);
      this.$emit("addParameter", { ...item, id: Date.now() });
    },
    //现在模板
    addTemplate() {
      this.$emit("addTemplate");
    },
    //操作小票模板 (type使用/编辑/复制/删除)
    handleTemplate(type, formatId) {
      console.log(formatId);
      let handlaTemplate = {
        type,
        formatId,
      };
      this.$emit("handleTemplate", handlaTemplate);
    },
  },
};
</script>

<style lang="scss" scoped>
.left-template {
  .menus {
    width: 72px;
    height: 100%;
    border-right: 1px solid #eee;
    .menu {
      width: 72px;
      height: 72px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.active {
        background-color: #edf4ff;
        border: 1px solid #1890ff;
      }
      .icon {
        width: 28px;
        height: 28px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .content {
    width: calc(100% - 72px);
    //滚动条
    .el-scrollbar {
      height: 100%;
      overflow: hidden;
      ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
      }
    }
    // 模板
    .template {
      height: 100%;
      .btn {
        margin: 20px;
        border: 1px solid #eee;
        height: 36px;
        line-height: 38px;
        text-align: center;
        &:hover {
          color: #1890ff;
          border: 1px solid #1890ff;
        }
      }
      .template-list {
        height: calc(100% - 76px);
        padding-bottom: 20px;
        .template-item {
          margin: 0 20px 20px;
          background-color: #f8f8f8;
          border: 1px solid #f1f1f1;
          &.active {
            border: 1px solid #1890ff;
          }
          .serialNum {
            width: 100%;
            text-align: center;
          }
          .template {
            height: calc(100% - 50px);
            margin: 12px;
            padding: 20px;
            background-color: #fff;
            width: calc(100% - 24px);
            .formatContent {
              // 文本超出换行
              white-space: pre-wrap;
              white-space: -moz-pre-wrap;
              white-space: -pre-wrap;
              white-space: -o-pre-wrap;
              word-wrap: break-word;
            }
          }
          .templateBtns {
            height: 50px;
            background-color: #fff;
            color: #1890ff;
            display: flex;
            border-top: 1px solid #f1f1f1;

            .line {
              width: 1px;
              height: 50px;
              background-color: #f1f1f1;
            }
            .use-btn {
              width: 40%;
              height: 100%;
              cursor: pointer;
            }
            .edit-btn,
            .copy-btn,
            .del-btn {
              width: 30%;
              height: 100%;
              cursor: pointer;
            }
            .disabled {
              color: #fff;
              background-color: #ccc;
              cursor: auto;
            }
          }
        }
      }
    }
    //参数
    .parameter {
      height: 100%;
      .el-input {
        margin: 20px;
        width: calc(100% - 40px);
      }
      .parameter-list {
        height: calc(100% - 128px);

        .parameter-item {
          padding: 0 16px;
          margin: 10px 20px;
          margin-top: 0;
          height: 46px;
          background-color: #f9f9f9;
          border: 1px solid #eee;
          font-weight: 700;
        }
      }
      .moreParameterBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-top: 1px solid #eee;
        height: 52px;
        color: #1890ff;
      }
    }
  }
  .el-textarea__inner {
    border: none;
    padding: 5px 0;
  }
}
</style>
